//console.log("tiny-slider");

import { tns } from "tiny-slider";

const tnsSlider = document.querySelectorAll(".tinyslider");
tnsSlider.forEach((slider) => {
  const tnsSlider = tns({
    container: slider,
    autoplayTimeout: 3000,
    items: 1,
    autoplay: true,
    mouseDrag: true,
    swipeAngle: 30,
    controls: false,
    nav: false,
    autoplayButton: false,
    autoplayButtonOutput: false,
    controlsText: ["", ""],
  });
});
