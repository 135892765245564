import "./components/gsap";
// * TINY SLIDER
// https://ganlanyuan.github.io/tiny-slider/demo/
import tinySlider from "./components/tiny-slider";

// * aggiungere nei css height: calc(var(--vh, 1vh) * 100) !important;
// https://github.com/Hiswe/vh-check
import vhCheck from "vh-check";
vhCheck({
  bind: false,
  redefineVh: true,
});
/*
$(document).ready(function () {
  console.log("ok");
});

jQuery(function () {
  console.log("ok");
});
*/

jQuery(function ($) {
  //aggiungo id progressivo alle section
  $("section").each(function (i, div) {
    div.id = "sec" + (i + 1); //i starts at 0
  });

  //casebox loading random order
  var caseHistory = $(".case-random");
  for (var i = 0; i < caseHistory.length; i++) {
    var target = Math.floor(Math.random() * caseHistory.length - 1) + 1;
    var target2 = Math.floor(Math.random() * caseHistory.length - 1) + 1;
    caseHistory.eq(target).before(caseHistory.eq(target2));
  }

  var caseLink = $(".showcase-small");
  for (var i = 0; i < caseLink.length; i++) {
    var target = Math.floor(Math.random() * caseLink.length - 1) + 1;
    var target2 = Math.floor(Math.random() * caseLink.length - 1) + 1;
    caseLink.eq(target).before(caseLink.eq(target2));
  }

  /*
  $(".recensione > div").readmore({
    collapsedHeight: 189,
    embedCSS: false,
    speed: 75,
    moreLink: '<a href="#">Leggi tutto</a>',
    lessLink: '<a href="#">Leggi meno</a>',
  });
  */

  /*
  $(".menu-item-has-children").hover(function () {
    $(this).toggleClass("submenu-on");
  });
  */
});
